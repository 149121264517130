import React, { useState } from 'react'
import { NavLink, Navigate } from 'react-router-dom'
import { 
  Button, Form, Grid, Segment, Message,Header, Label
  ,Dropdown
  ,Input,
  Icon
 } from 'semantic-ui-react'
import { useAuth } from '../context/AuthContext'
import { movieApi } from '../misc/MovieApi'
import { parseJwt, handleLogError } from '../misc/Helpers'

const style = {
  h1: {
    marginTop: '3em',
  },
  h2: {
    margin: '4em 0em 2em',
  },
  h3: {
    marginTop: '2em',
    padding: '2em 0em',
  },
  last: {
    marginBottom: '300px',
  }
  
}
const optionsAirline = [
  { key: 1, text: 'China Airline - CI', value: 'CI' },
  { key: 2, text: 'Nippon Airline - ANA', value: 'ANA' },
  { key: 3, text: 'Eva Airline - BR', value: 'BR' },
]
const optionsFrom = [
  { key: 1, text: 'SFO', value: 'SFO' },
  { key: 2, text: 'SFA', value: 'SFA' }  
]
const optionsTo = [
  { key: 1, text: 'SGN', value: 'SGN' },
  { key: 2, text: 'SGS', value: 'SGS' }
  
]

function Signup() {
  const Auth = useAuth()
  const isLoggedIn = Auth.userIsAuthenticated()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleInputChange = (e, { name, value }) => {
    if (name === 'username') {
      setUsername(value)
    } else if (name === 'password') {
      setPassword(value)
    } else if (name === 'name') {
      setName(value)
    } else if (name === 'email') {
      setEmail(value)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!(username && password && name && email)) {
      setIsError(true)
      setErrorMessage('Please, inform all fields!')
      return
    }

    const user = { username, password, name, email }

    try {
      const response = await movieApi.signup(user)
      const { accessToken } = response.data
      const data = parseJwt(accessToken)
      const authenticatedUser = { data, accessToken }

      Auth.userLogin(authenticatedUser)

      setUsername('')
      setPassword('')
      setIsError(false)
      setErrorMessage('')
    } catch (error) {
      handleLogError(error)
      if (error.response && error.response.data) {
        const errorData = error.response.data
        let errorMessage = 'Invalid fields'
        if (errorData.status === 409) {
          errorMessage = errorData.message
        } else if (errorData.status === 400) {
          errorMessage = errorData.errors[0].defaultMessage
        }
        setIsError(true)
        setErrorMessage(errorMessage)
      }
    }
  }

  if (isLoggedIn) {
    return <Navigate to='/' />
  }

  return (
    <Grid textAlign='center'>
      <Grid.Column style={{ maxWidth: 860 }}>
      <Form size='large' onSubmit={handleSubmit}>           
          <Segment>   
          <Header as='h3' content='Add New MAWB' textAlign='center' />
          <Grid columns={3} doubling>
            <Grid.Column>        
              <Label>MAWB Id</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='info'
              iconPosition='left'
              placeholder='Please enter MAWB Id'
              onChange={handleInputChange}
            />
            </Grid.Column>
            <Grid.Column>
            <Label>Airlines</Label>
            <Dropdown placeholder='Select an Airline' search selection options={optionsAirline} />
                       
            </Grid.Column>
            <Grid.Column>            
            </Grid.Column>
            <Grid.Column>
            <Label>From</Label>
            <Dropdown placeholder='Select from location' search selection options={optionsFrom} />
            </Grid.Column>
           
            <Grid.Column>
            <Label>To &nbsp;&nbsp;</Label>
            <Dropdown placeholder='Select from location' search selection options={optionsTo} />
            </Grid.Column>
            <Grid.Column>            
            </Grid.Column>
            <Grid.Column>            
            </Grid.Column>
            <Grid.Column>            
            </Grid.Column>
            <Grid.Column className='button-column'>    
            <Button icon labelPosition='right' >
            Create<Icon name='add' />
          </Button>        
            </Grid.Column>
          </Grid>                         
           
          </Segment>
        </Form>
        <br/>
        <Form size='large' onSubmit={handleSubmit}>           
          <Segment>   
          <Header as='h3' content='New MAWB Item' textAlign='center' />
          <Grid columns={3} doubling>
            <Grid.Column>        
              <Label>SFO</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='info'
              iconPosition='left'
              placeholder='Enter SFO'
              onChange={handleInputChange}
            />
            </Grid.Column>
            <Grid.Column>
            <Label>MAWB Id</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='info'
              iconPosition='left'
              placeholder='Enter MAWB Id'
              onChange={handleInputChange}
            />
                       
            </Grid.Column>
            <Grid.Column>            
            </Grid.Column>
            <Grid.Column>     
            <Label>No. of Piece RCP</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='info'
              iconPosition='left'
              placeholder='Enter No. of Piece'
              onChange={handleInputChange}
            />    
            </Grid.Column>
            <Grid.Column>
            <Label>Requested Flight/Date</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='calendar'
              iconPosition='left'
              placeholder='Enter Requested Flight/Date'
              onChange={handleInputChange}
            />
            </Grid.Column>
            <Grid.Column>     
            <Label>Executed Date</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='calendar'
              iconPosition='left'
              placeholder='Enter Executed Date'
              onChange={handleInputChange}
            />       
            </Grid.Column>
            <Grid.Column>
            <Label>Gross Weight</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='weight'
              iconPosition='left'
              placeholder='Enter Gross Weight'
              onChange={handleInputChange}
            />
            </Grid.Column>
           
            <Grid.Column>     
            <Label>Chargeable Weight</Label>
            <Form.Input
              fluid
              autoFocus
              name='mawbid'
              icon='weight'
              iconPosition='left'
              placeholder='Enter Gross Weight'
              onChange={handleInputChange}
            />    
            </Grid.Column>   
            <Grid.Column>            
            </Grid.Column>
            <Grid.Column>            
            </Grid.Column>
            <Grid.Column>            
            </Grid.Column>         
            <Grid.Column className='button-column'>    
            <Button icon labelPosition='right' >
            Create<Icon name='add' />
          </Button>        
            </Grid.Column>
           
          </Grid>                         
           
          </Segment>
        </Form>
        <br/>
        <Form size='large' onSubmit={handleSubmit}>
          <Segment>          
            <Form.Input
              fluid
              autoFocus
              name='username'
              icon='user'
              iconPosition='left'
              placeholder='Username'
              onChange={handleInputChange}
            />
            <Form.Input
              fluid
              name='password'
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              onChange={handleInputChange}
            />
            <Form.Input
              fluid
              name='name'
              icon='address card'
              iconPosition='left'
              placeholder='Name'
              onChange={handleInputChange}
            />
            <Form.Input
              fluid
              name='email'
              icon='at'
              iconPosition='left'
              placeholder='Email'
              onChange={handleInputChange}
            />
            <Button color='purple' fluid size='large'>Signup</Button>
          </Segment>
        </Form>
        
        <Message>{`Already have an account? `}
          <NavLink to="/login" color='purple'>Login</NavLink>
        </Message>
        {isError && <Message negative>{errorMessage}</Message>}
      </Grid.Column>
    </Grid>
  )
}

export default Signup